import { fabric } from "fabric-with-erasing";
import { v1 as uuid } from "uuid";
import windowBackground from "../../images/backgroundDouble.png";
import { dfItemColor, saveCanvasState } from "../../helpers/helpers";
import itemsBackgroundsArray from "../../inventaire/itemsBackgroundsArray";
import { getPsptFabricObj } from "../../helpers/fabricPerspective";

let windowImage;
let optionImage;
let backgroundUrl = windowBackground;

export function addWindows(canvas, properties, callbackFn, realScaleFactor) {

  if (properties.isSelectAll) {
    replaceAllWindows(canvas, properties, callbackFn, realScaleFactor);
    return;
  } 
  var tName = properties.tName;
  var typeName = properties.typeName;
  var url = properties.window;
  var windowURL = windowURL;
  var optionUrl = optionUrl;
  var window = properties.window;

  findBackground(tName);

  const updateGroupColor = (group, color, canvas) => {
    if (!group) {
      console.error("Group is undefined or invalid.");
      return;
    }
  
    if (!canvas) {
      console.error("Canvas instance is undefined.");
      return;
    }
  

  
    // Find the object with the BlendColor filter
    const targetObject = group._objects.find((obj) => 
      obj.filters && obj.filters.some((f) => f.type === "BlendColor")
    );
  
    if (!targetObject) {
      console.error("No object with BlendColor filter found in group.");
      return;
    }

  
    // Ensure the BlendColor filter exists and update it
    const blendColorFilter = targetObject.filters.find((f) => f.type === "BlendColor");
    if (blendColorFilter) {
      try {
  
  
        // Update the filter's color
        blendColorFilter.color = color;
  
        // Reapply filters
        targetObject.applyFilters();
  
        // Render the canvas
        canvas.renderAll();
      } catch (error) {
        console.error("Error updating group color or rendering canvas:", error);
      }
    } else {
      console.error("BlendColor filter is not defined in target object.");
    }
  };
  
  

  let promises = [];

  let loadWindowImageImage = new Promise((resolve, reject) => {


    fabric.Image.fromURL(
      url,
      function (oImg) {
        const naturalWidth = oImg.width;
        const naturalHeight = oImg.height;

        adjustImageScale(oImg, properties, naturalWidth, naturalHeight);
        windowImage = oImg;

        windowImage.set({
          windowItemId: uuid(),
          windowType: tName,
          windowImageURL: windowURL,
          crossOrigin: "anonymous",
          itemType: "window"
        });
        var filter = new fabric.Image.filters.BlendColor({
          color: dfItemColor,
          mode: "multiply",
          alpha: 0.95,
        });

        // windowImage.scaleToWidth(160);
        windowImage.filters.push(filter);
        windowImage.applyFilters();
        canvas.centerObject(windowImage);
        resolve(windowImage);
      },
      {
        crossOrigin: "anonymous",
      }
    );
  });

  promises.push(loadWindowImageImage);

  optionUrl = backgroundUrl;

  //add options like grid
  let loadOptionImageImage = new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      optionUrl,
      function (oImg) {
        const naturalWidth = oImg.width;
        const naturalHeight = oImg.height;

        adjustImageScale(oImg, properties, naturalWidth, naturalHeight);
        optionImage = oImg;

        optionImage.set({
          optionItemId: uuid(),
          optionType: tName,
          windowGridType: properties.windowGridType,
          crossOrigin: "anonymous",
          originX: "center",
          originY: "center",
        });

        canvas.centerObject(optionImage);
        canvas.uniformScaling = false;
        resolve(optionImage);
      },
      {
        crossOrigin: "anonymous",
      }
    );
  });

  promises.push(loadOptionImageImage);

  // add backgroung Image
  let loadBackgroundImageImage = new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      backgroundUrl,
      function (oImg) {
        const naturalWidth = oImg.width;
        const naturalHeight = oImg.height;

        adjustImageScale(oImg, properties, naturalWidth, naturalHeight);
        let backgroundImage = oImg;

        backgroundImage.set({
          backgroundItemId: uuid(),
          crossOrigin: "anonymous",
        });
        canvas.centerObject(backgroundImage);
        // backgroundImage.scaleToWidth(160);
        canvas.uniformScaling = false;
        resolve(backgroundImage);
      },
      {
        crossOrigin: "anonymous",
      }
    );
  });

  promises.push(loadBackgroundImageImage);

  let topPosition, leftPosition;

  if (properties.isAICall) {

    // console.log("properties .top and left", properties.top, properties.left)
    // Use AI-determined positions exclusively
    topPosition = properties.top;
    leftPosition = properties.left;
} else {
    // For manual selections or replacements, use provided coordinates or default to fallback
    topPosition = properties.top !== undefined ? properties.top : 100;
    leftPosition = properties.left !== undefined ? properties.left : canvas.getWidth() / 2;
}



  Promise.all(promises)
    .then((images) => {
      let windowImage = images[0];
      let optionImage = images[1];
      let backgroundImage = images[2];



      let windowStackImage = new fabric.Group(
        [backgroundImage, optionImage, windowImage],
        {
          windowStackImageId: uuid(),
          groupId: uuid(),
          dirty: true,
          selectable: true,
          itemUrl: window,
          fill: properties.fill, // Use the inherited color
          windowType: tName, // Ensure this is set on the group
          itemType: "window",
          itemColor: dfItemColor,
          preserveObjectStacking: true,
          top: topPosition,
          left: leftPosition,
          originalOpacity: 1,
          usePerspective: true,
          realWidth: (properties.width / realScaleFactor).toFixed(1),
          realHeight: (properties.height / realScaleFactor).toFixed(1),
        }
      );



      
      
      // Add the group to the canvas and render
      getPsptFabricObj(canvas, windowStackImage).then((poly) => {
        canvas.discardActiveObject().renderAll();
        canvas.add(poly);
        poly.itemColor = properties.fill;
        poly.itemUrl = window;
        poly.windowType = tName;
        canvas.bringToFront(poly);
        if (callbackFn) callbackFn();
        canvas.setActiveObject(poly);
      
        if (canvas.isPerspective) {
          windowStackImage.visible = false;
          canvas.add(windowStackImage);
        }
      });
 // Update the color of the last object in the group
 updateGroupColor(windowStackImage, properties.fill, canvas);

//  console.log("local scale factor:", windowStackImage)

      // Assume windowStackImage is already created and has a known position and dimensions
      const textPositionLeft = windowStackImage.left - windowStackImage.width / 3;
      const textPositionTop = windowStackImage.top;

      const textInfo = `W: ${windowStackImage.realWidth} in, H: ${windowStackImage.realHeight} in`; // Display in inches

      // Create a Fabric.js text object for this information
      const textObject = new fabric.Text(textInfo, {
        fontSize: 12,
        fill: 'black',
        fontFamily: 'Arial',
        left: textPositionLeft,
        top: textPositionTop,
        angle: -90, // Rotate the text object by 90 degrees to make it vertical
        originX: 'center', // This centers the text horizontally around its midpoint
      });

      // Measure text to create a background rectangle
      const textWidth = textObject.width + 10; // Adding some padding
      const textHeight = textObject.height; // Adding some padding

      // Create a rectangle to serve as the background
      const backgroundRect = new fabric.Rect({
        width: textWidth,
        height: textHeight,
        fill: 'yellow',
        left: textPositionLeft, // Slightly offset to center behind the text
        top: textPositionTop, // Slightly offset above the text
        angle: -90, // Rotate the text object by 90 degrees to make it vertical
        rx: 5, // Optional: add rounded corners
        ry: 5, // Optional: add rounded corners
        originX: 'center', // Adjust originX and originY as needed for correct positioning  
      });

      // Group the text and background so they move together
      const textWithBackground = new fabric.Group([backgroundRect, textObject], {
        left: textPositionLeft,
        top: textPositionTop,
        isDimension: true, // custom attribute to mark dimension objects
        selectable: false, // Make the object unselectable
      });

      // Add the grouped text and background to the canvas
      // if (!properties.designerTriggered && properties.isAICall && properties.isDimensionsVisible) { canvas.add(textWithBackground);canvas.bringToFront(textWithBackground) }

      saveCanvasState(canvas);
      canvas.renderAll();
      // The rest of your existing code...
    })
    .catch((error) => {
      console.error("Error loading images:", error);
    });

}

// Helper function to replace all windows on the canvas
function replaceAllWindows(canvas, properties, callbackFn, realScaleFactor) {
  const itemsToReplace = [];

  // Step 1: Collect all window items and their properties
  canvas.getObjects().forEach((obj) => {
      if (obj.itemType === "window") {
          const itemData = {
              left: obj.left,
              top: obj.top,
              scaleX: obj.scaleX,
              scaleY: obj.scaleY,
              angle: obj.angle,
              itemType: obj.itemType,
              windowType: obj.windowType,
              group: obj.type === "group" ? obj : null, // Save group reference if applicable
          };
          itemsToReplace.push(itemData);
      }
  });

  // Step 2: Replace content for each item
  itemsToReplace.forEach((itemData) => {
      if (itemData.group) {
          // Handle grouped items
          replaceGroupContent(itemData.group, properties, realScaleFactor);
      } else {
          // Handle individual items
          const newProperties = {
              ...properties,
              left: itemData.left,
              top: itemData.top,
              scaleX: itemData.scaleX,
              scaleY: itemData.scaleY,
              angle: itemData.angle,
          };

          console.log("Replacing individual item with properties:", newProperties);
          addWindows(canvas, newProperties, callbackFn, realScaleFactor);
      }
  });

  // Render the canvas after all operations
  canvas.renderAll();
}

function replaceGroupContent(group, properties, realScaleFactor) {
  group._objects.forEach((subObject) => {
      if (subObject.type === "image") {
          // Replace the image source
          subObject.set({
              src: properties.window,
              crossOrigin: "anonymous",
          });

          // Update scale if required
          subObject.scaleX = properties.scaleX || subObject.scaleX;
          subObject.scaleY = properties.scaleY || subObject.scaleY;
      }
  });

  // Apply the changes to the group and re-render
  group.dirty = true;
  group.addWithUpdate();
}





// we add a standard background
function findBackground(tName) {

  const itemName = tName;

  let tempBg = itemsBackgroundsArray.filter((item) =>
    item.name.includes(itemName)
  );
  backgroundUrl = tempBg[0].bgUrl;

}
function adjustImageScale(oImg, properties, naturalWidth, naturalHeight) {
  if (properties.isAICall) {
    // AI-determined scale factors based on the target dimensions
    const scaleX = properties.width / naturalWidth;
    const scaleY = properties.height / naturalHeight;
    oImg.scaleX = scaleX;
    oImg.scaleY = scaleY;

  } else if (typeof properties.width === "number" && typeof properties.height === "number") {
    // Explicit check for number type to ensure dimensions are set
    const targetScaleX = properties.width / naturalWidth;
    const targetScaleY = properties.height / naturalHeight;
    oImg.scaleX = targetScaleX;
    oImg.scaleY = targetScaleY;

  } else {
    // Default scaling if no specific dimensions provided
    oImg.scaleX = 0.2;
    oImg.scaleY = 0.2;

  }
}
