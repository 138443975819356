import React, { useState, useEffect, useRef } from "react";
import { Rating } from "@mui/material";
import "./DetectionFeedback.css";

const DetectionFeedback = ({ canvas, projectImageUrl, onSubmit, onClose, t }) => {
  const [rating, setRating] = useState(0); // Default rating
  const [notes, setNotes] = useState(""); // Default notes
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackId, setFeedbackId] = useState(null); // Track feedback ID for updates
  const feedbackSubmitted = useRef(false); // Track if feedback is already submitted

  useEffect(() => {
    const submitInitialFeedback = async () => {
      if (!canvas || feedbackSubmitted.current) return;

      feedbackSubmitted.current = true; // Mark as submitted

      const canvasDataUrl = canvas.toDataURL({
        format: "webp",
        quality: 0.8,
      });

      const initialFeedback = {
        rating: 0,
        notes: "",
        projectImageUrl,
        detectionImage: canvasDataUrl,
      };

      try {
        const feedbackId = await onSubmit(initialFeedback);
        setFeedbackId(feedbackId);
      } catch (error) {
        console.error("Error submitting initial feedback:", error);
      }
    };

    submitInitialFeedback();
  }, [canvas, projectImageUrl, onSubmit]);

  const handleSubmit = async () => {
    if (!feedbackId) return;

    setIsSubmitting(true);
    try {
      const updatedFeedback = {
        rating: rating || 0,
        notes: notes || "",
      };

      await onSubmit(updatedFeedback, feedbackId); // Update Firestore document
      alert(t("feedback.updatedSuccess"));
    } catch (error) {
      console.error("Error updating feedback:", error);
      alert(t("feedback.updatedError"));
    } finally {
      setIsSubmitting(false);
      if (onClose) onClose(); // Close feedback component
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <div className="detection-feedback">
      <div className="feedback-header">
        {t("detectionAdvice")}
        <button className="close-button" onClick={handleClose}>
          ×
        </button>
      </div>
      <div className="rating-section">
        <h3>{t("feedback.rateDetection")}</h3>
        <Rating
          name="detection-rating"
          value={rating}
          onChange={(event, newValue) => setRating(newValue)}
        />
      </div>
      <div className="notes-section">
        <h3>{t("feedback.notes")}</h3>
        <textarea
          rows="1"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder={t("feedback.notesPlaceholder")}
          style={{ resize: "vertical" }}
        />
      </div>
      <div className="submit-section">
        <button
          className="submit-feedback-button"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? t("feedback.submitting") : t("feedback.submit")}
        </button>
      </div>
    </div>
  );
};

export default DetectionFeedback;
