import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useTranslation, Trans } from "react-i18next";
import "../homepage/home.css";
import FeatureSection from "./FeatureSection";
import Contact from "./Contact";
import About from "./About";
import ImageSlider from "../../components/ImageSlider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WelcomeVideoModal from "./WelcomeVideoModal";

function Home(props) {
  const { i18n, t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    if (!i18n.hasResourceBundle(i18n.language, "aiFeatureLang")) {
      i18n.loadNamespaces("aiFeatureLang").then(() => {
        console.log("aiFeatureLang namespace loaded");
      }).catch(err => {
        console.error("Error loading aiFeatureLang namespace:", err);
      });
    }
  }, [i18n]);

  useEffect(() => {
    // Trigger a re-render when the language changes
    setIsCollapsed(true);
  }, [i18n.language]);

  const images = [
    "/maisons-modeles/converted_images/maison_demo_App-min_old2.webp",
    "/maisons-modeles/converted_images/test_erase_1-min.webp",
    "/maisons-modeles/converted_images/test_erase_1 (1)-min.webp",
    "/maisons-modeles/converted_images/test_erase_1 (2)-min.webp",
  ];

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <div>
      {/* Hero Section */}
      <header className="masthead pb-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 text-center">
              <h2 className="text-white pt-3">{t("masthead.transformYourHome")}</h2>
              <h1 className="text-light pt-4">{t("masthead.dontJustImagine")}</h1>
              <a
                href="#guest"
                className="btn btn-outline-light mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  props.handleGuestLogin();
                }}
              >
                {t("masthead.tryAsGuest")}
              </a>
            </div>
            <div className="col-md-5">
              <WelcomeVideoModal isInline={true} />
            </div>
          </div>
        </div>
      </header>

      <section className="feature-section ai-feature-section py-5">
        <div className="container">
          {/* Heading Section */}
          <h2>{t("aiFeatureLang.intro.title")}</h2>
          <p className="subtitle">{t("aiFeatureLang.intro.description")}</p>

          {/* Steps Section */}
          <div className="row">
            {["step1", "step2", "step3", "step4"].map((step, index) => (
              <div key={index} className="col-lg-3 col-md-6 col-sm-12 text-center mb-4">
                <img
                  src={t(`aiFeatureLang.steps.${step}.mediaPath`)}
                  alt={t(`aiFeatureLang.steps.${step}.altText`)}
                  className="img-fluid mb-3"
                />
                <h5 className="text-primary">{t(`aiFeatureLang.steps.${step}.title`)}</h5>
                <p className="text-muted">{t(`aiFeatureLang.steps.${step}.description`)}</p>
              </div>
            ))}
          </div>

          {/* CTA Section */}
          <div className="text-center cta-container">
            <a
              href="#guest"
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();
                props.handleGuestLogin();
              }}
            >
              {t("aiFeatureLang.cta")}
            </a>
          </div>
        </div>
      </section>




      {/* Slider Section */}
      <section className="py-5">
        <div className="container">
          <h2 className="text-center showcase-title mb-4">{t("slider.title")}</h2>
          <div className="row">
            <div className="col-md-6">
              <img
                src={images[0]}
                alt="Original House"
                className="img-fluid rounded shadow-sm"
              />
            </div>
            <div className="col-md-6">
              <ImageSlider images={images} />
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section id="howitworks" className="how-it-works py-5 bg-light">
  <div className="container"> {/* Full-width container */}
    <FeatureSection
      titleKey="homepage.how_it_works.title"
      descriptionKeys={[
        "homepage.how_it_works.steps.step1",
        "homepage.how_it_works.steps.step2",
        "homepage.how_it_works.steps.step3",
        "homepage.how_it_works.steps.step4",
        // "homepage.how_it_works.steps.step5",
      ]}
      handleGuestLogin={props.handleGuestLogin}
      mediaKey="feature1.mediaPath"
    />
  </div>
</section>



      <section id="about">
        <About />
      </section>

      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}

export default Home;
